<template>
  <div>
    <div v-if="!isLoading" class="float-md-right mt-md-n3 mb-1 mb-md-0 ml-75 mr-md-2">
      <div class="d-flex align-items-center">
        <v-select
          v-if="editing"
          v-model="selectedLanguage"
          :options="locales"
          :searchable="false"
          :clearable="false"
          label="text"
          :reduce="(language) => language.value"
          style="width: 160px"
        />
        <feather-icon
          v-if="canDelete && !editing && !isCreating"
          v-b-tooltip.hover.bottom
          icon="Trash2Icon"
          :title="$t('unshare.tooltip')"
          size="20"
          style="cursor: pointer"
          class="text-primary float-right zindex-1"
          @click="openUnshareModal(item)"
        />
        <feather-icon
          v-if="canEdit && !editing && !isCreating"
          v-b-tooltip.hover.bottom
          icon="Edit2Icon"
          size="20"
          :title="$t('form-create-item.edit')"
          class="text-primary edit-icon float-right ml-1 zindex-1"
          role="button"
          @click="toggleEdit(item)"
        />
        <feather-icon
          v-if="canAdd && !editing && !isCreating"
          v-b-tooltip.hover.bottom
          icon="PlusSquareIcon"
          size="20"
          class="text-primary share-icon ml-1 zindex-1"
          :title="$t('articles.detail.new-page')"
          @click="createNewPage(id)"
        />
        <feather-icon
          v-if="canShare && !editing && !isCreating"
          v-b-tooltip.hover.bottom
          icon="Share2Icon"
          :title="$t('share.tooltip')"
          size="20"
          class="text-primary share-icon ml-1 zindex-1"
          @click="isShareModalVisible = true"
        />
        <b-button
          v-if="editing"
          size="sm"
          class="mx-1 zindex-1"
          variant="primary"
          @click="handleEditItem"
        >
          {{ $t("form.actions.save") }}
        </b-button>
        <feather-icon
          v-if="editing"
          icon="XCircleIcon"
          size="20"
          class="text-primary edit-icon zindex-1"
          role="button"
          @click="toggleEdit"
        />
      </div>
    </div>

    <div class="blog-container">
      <b-spinner
        v-if="isLoading"
        class="center-x my-3"
        small
        variant="primary"
        type="grow"
      />
      <content-with-sidebar
        v-if="item && !isLoading"
        class="cws-container cws-sidebar-left blog-wrapper"
      >
        <b-row>
          <b-col
            cols="12"
            md="3"
            order-md="1"
            order="2"
          >
            <!-- Sidebar, relate blogs -->

            <div v-if="!editing" slot="sidebar" class="py-lg-0 index">
              <div class="blog-recent-posts pb-2 pt-2">
                <div class="d-flex justify-content-between align-items-center mb-1 px-2">
                  <h6 class="section-label mb-0">
                    {{ $t("articles.detail.navigation") }}
                  </h6>
                  <!--                 <div v-if="showNavigation" class="d-flex pointer align-items-center" @click="toggleNavigation">
                <p class="small mb-0 mr-25">{{ $t("articles.detail.hide") }}</p>
                <feather-icon  class="pointer" icon="ChevronDownIcon"
                />
                </div>
                <div v-else class="d-flex pointer align-items-center" @click="toggleNavigation">
                <p class="small mb-0 mr-25">{{ $t("articles.detail.show") }}</p>
                <feather-icon  class="pointer" icon="ChevronUpIcon"
                />
                </div> -->
                </div>
                <div class="title-index mx-2" />
                <div v-if="childrenItem.length>0 && showNavigation">
                  <b-media
                    v-for="(subItem, index) in childrenItem"
                    :key="index"
                    no-body
                    class="ml-0 flex-column d-flex w-100"
                  >
                    <b-media-body class="d-flex align-items-center pointer justify-content-between subitem py-75 px-2 w-100">
                      <b-link
                        class="text-body-heading html-text-ellipsis-2"
                        :href="'#'+ subItem.key"
                      >
                        <div class="d-flex align-items-center">
                          <p class="mb-0 title mr-50">
                            {{ index+1 }}.
                          </p>
                          <p class="title mb-0">
                            {{
                              subItem.name[currentLocale] ||
                                Object.values(subItem.name)[0]
                            }}
                          </p>
                        </div>
                      </b-link>
                      <div class="d-flex align-items-center" style="width:76px">
                        <feather-icon
                          v-if="canDelete"
                          v-b-tooltip.hover.bottom
                          icon="Trash2Icon"
                          size="16"
                          class="mr-1 create"
                          :title="$t('unshare.simple-title')"
                          @click="openUnshareModal(subItem)"
                        />
                        <feather-icon
                          v-if="canEdit"
                          v-b-tooltip.hover.bottom
                          icon="Edit2Icon"
                          size="16"
                          class="mr-1 create"
                          :title="$t('form-create-item.edit')"
                          @click="toggleEdit(subItem)"
                        />
                        <feather-icon
                          v-if="canAdd"
                          v-b-tooltip.hover.bottom
                          icon="PlusSquareIcon"
                          size="16"
                          class="create"
                          :title="$t('articles.detail.new-subpage')"
                          @click="createNewPage(subItem.key, item)"
                        />
                      </div>
                    </b-media-body>
                    <div v-if="subItem.subpages && subItem.subpages.length > 0" class="w-100 pointer">
                      <div
                        v-for="(subPage, index2) in subItem.subpages"
                        :key="index2"
                        class="d-flex align-items-center justify-content-between subitem py-25 px-2 w-100"
                      >
                        <b-link
                          class="text-body-heading html-text-ellipsis-2"
                          :href="'#'+ subPage.key"
                        >
                          <div class="d-flex align-items-center ml-2">
                            <p class="mb-0 title mr-50">
                              {{ index+1 }}.{{ index2+1 }}.
                            </p>
                            <p class="title mb-0">
                              {{
                                subPage.name[currentLocale] ||
                                  Object.values(subPage.name)[0]
                              }}
                            </p>
                          </div>
                        </b-link>
                        <div class="d-flex align-items-center" style="width:46px">
                          <feather-icon
                            v-if="canDelete"
                            v-b-tooltip.hover.bottom
                            icon="Trash2Icon"
                            size="16"
                            class="mr-1 create"
                            :title="$t('unshare.simple-title')"
                            @click="openUnshareModal(subPage)"
                          />
                          <feather-icon
                            v-if="canEdit"
                            v-b-tooltip.hover.bottom
                            icon="Edit2Icon"
                            size="16"
                            class="create"
                            :title="$t('form-create-item.edit')"
                            @click="toggleEdit(subPage)"
                          />
                        </div>
                      </div>
                    </div>
                  </b-media>
                </div>
                <div v-else-if="childrenItem.length===0 && showNavigation" class="py-75 px-2">
                  {{ $t("articles.detail.no-articles") }}
                </div>
                <div v-if="!showNavigation" class="py-75 px- text-center text-muted subitem">
                  {{ $t("articles.detail.hide-articles") }}
                </div>
                <div v-if="!showNavigation" class="title-index mx-2" />
                <div
                  v-if="canAdd"
                  v-b-hover="handleHover"
                  variant="link"
                  class="px-2 py-75 d-flex align-items-center pointer subitem"
                  :class="isHovered?'text-primary':'text-muted'"
                  @click="createNewPage(id)"
                >
                  <b-icon-plus
                    font-scale="1.8"
                    variant="primary"
                    class="ml-n50"
                    :class="isHovered?'text-primary':'text-muted'"
                  />
                  <p class="mb-0 ml-25">
                    {{ $t("articles.detail.new-page") }}
                  </p>
                </div>
              </div>
            </div>
            <div v-if="!editing" slot="sidebar" class="py-lg-0 mt-1 index">
              <div class="blog-recent-posts pb-2 pt-2">
                <div class="mb-1 px-2 d-flex justify-content-between">
                  <h6 class="section-label mb-0">
                    {{ $t("articles.list") }}
                  </h6>
                  <div v-if="showList" class="d-flex pointer align-items-center" @click="toggleList">
                    <p class="small mb-0 mr-25">
                      {{ $t("articles.detail.hide") }}
                    </p>
                    <feather-icon
                      class="pointer"
                      icon="ChevronDownIcon"
                    />
                  </div>
                  <div v-else class="d-flex pointer align-items-center" @click="toggleList">
                    <p class="small mb-0 mr-25">
                      {{ $t("articles.detail.show") }}
                    </p>
                    <feather-icon
                      class="pointer"
                      icon="ChevronUpIcon"
                    />
                  </div>
                </div>
                <div class="title-index mx-2" />
                <div v-if="showList">
                  <b-media
                    v-for="(recentpost, index) in recentPosts"
                    :key="index"
                    no-body
                    class="ml-0"
                  >
                    <b-media-body v-if="!recentpost.parentKey" class="subitem py-75 px-2">
                      <h6 class="blog-recent-post-title">
                        <b-link
                          class="text-body-heading html-text-ellipsis-2"
                          :to="{
                            name: 'article',
                            params: { id: recentpost.key },
                          }"
                        >
                          {{
                            recentpost.name[currentLocale] ||
                              Object.values(recentpost.name)[0]
                          }}
                        </b-link>
                      </h6>
                      <span class="text-muted mb-0">
                        {{ $t("articles.updated-at") }}
                        {{ timestampToTime(recentpost.updatedAt) }}
                      </span>
                    </b-media-body>
                  </b-media>
                </div>
                <div v-if="!showList" class="py-75 px- text-center text-muted subitem">
                  {{ $t("articles.detail.hide-list") }}
                </div>
                <div v-if="!showList" class="title-index mx-2" />
              </div>
            </div>
          </b-col>
          <b-col
            v-if="isCreating"
            cols="12"
            md="9"
            order-md="2"
            order="1"
          >
            <article-create
              :id="elementId"
              :is-new-page="true"
              :main-article="mainItem"
              @closeModal="closeModal"
            />
          </b-col>
          <b-col
            cols="12"
            :md="!editing? 9 : 12"
            order-md="2"
            order="1"
          >
            <!-- content -->
            <div v-if="!isCreating" class="blog-detail-wrapper">
              <b-row class="justify-content-md-center">
                <!-- blogs -->
                <b-col cols="12">
                  <b-card class="pb-lg-3">
                    <div class="mb-2 w-100" />

                    <div class="px-lg-3">
                      <h1 v-if="!editing" class="mb-0">
                        {{
                          item.name[currentLocale] ||
                            Object.values(item.name)[0]
                        }}
                      </h1>
                      <b-form-input
                        v-else
                        v-model="editForm.name[selectedLanguage]"
                        class="edit-title"
                        :placeholder="
                          editForm.name[selectedLanguage] ||
                            Object.values(item.name)[0]
                        "
                      />
                      <b-media no-body class="pt-1">
                        <!-- Owner and date-->
                        <b-media-body v-if="item.ownedByMember" class="mb-3">
                          <small class="text-muted mr-50">{{
                            $t("articles.created-by")
                          }}</small>
                          <small>
                            <b-link class="text-body">
                              {{ item.ownedByMember.name }}
                              {{ item.ownedByMember.surname }}
                            </b-link>
                          </small>
                          <span class="text-muted ml-75 mr-50">|</span>
                          <small
                            class="text-muted"
                          >{{ $t("articles.updated-at") }}
                            {{ timestampToTime(item.updatedAt) }}</small>
                        </b-media-body>
                      </b-media>
                    </div>

                    <!-- Description -->
                    <div
                      v-if="item.body && !editing"
                      class="blog-content mb-3 mx-lg-3 content-container"
                      v-html="
                        item.body[currentLocale] || Object.values(item.body)[0]
                      "
                    />
                    <quill-editor
                      v-if="editing"
                      v-model="editForm.body[selectedLanguage]"
                      class="px-lg-3"
                    />
                    <div v-if="!editing && childrenItem.length > 0" class="px-lg-3">
                      <div
                        v-for="(subItem, index) in childrenItem"
                        :id="subItem.key"
                        :key="index"
                        class="border-top pt-2 mb-3"
                      >
                        <div class="d-flex justify-content-between align-items-center mb-1">
                          <h3 class="mb-0">
                            {{ index + 1 }}. {{
                              subItem.name[currentLocale] ||
                                Object.values(subItem.name)[0]
                            }}
                          </h3>
                          <div class="d-flex align-items-center text-primary" style="width:76px">
                            <b-link v-if="canDelete">
                              <feather-icon
                                v-b-tooltip.hover.bottom
                                icon="Trash2Icon"
                                size="16"
                                class="mr-1 create"
                                :title="$t('unshare.simple-title')"
                                @click="openUnshareModal(subItem)"
                              />
                            </b-link>
                            <b-link v-if="canEdit" href="#">
                              <feather-icon
                                v-b-tooltip.hover.bottom
                                icon="Edit2Icon"
                                size="16"
                                class="mr-1 create"
                                :title="$t('form-create-item.edit')"
                                @click="toggleEdit(subItem)"
                              />
                            </b-link>
                            <b-link v-if="canAdd" href="#">
                              <feather-icon
                                v-b-tooltip.hover.bottom
                                icon="PlusSquareIcon"
                                size="16"
                                class="create"
                                :title="$t('articles.detail.new-subpage')"
                                @click="createNewPage(subItem.key, item)"
                              />
                            </b-link>
                          </div>
                        </div>
                        <div
                          v-if="subItem.body"
                          class="blog-content content-container"
                          v-html="
                            subItem.body[currentLocale] || Object.values(subItem.body)[0]
                          "
                        />

                        <div v-if="subItem.subpages&&subItem.subpages.length>0">
                          <div
                            v-for="(subpage, index2) in subItem.subpages"
                            :id="subpage.key"
                            :key="index2"
                            class="pt-2"
                          >
                            <div class="mb-1 d-flex align-items-center justify-content-between">
                              <h5 class="mb-0">
                                {{ index + 1 }}.{{ index2+1 }}. {{
                                  subpage.name[currentLocale] ||
                                    Object.values(subpage.name)[0]
                                }}
                              </h5>
                              <div class="d-flex align-items-center text-primary" style="width:46px">
                                <b-link v-if="canDelete">
                                  <feather-icon
                                    v-b-tooltip.hover.bottom
                                    icon="Trash2Icon"
                                    size="16"
                                    class="mr-1 create"
                                    :title="$t('unshare.simple-title')"
                                    @click="openUnshareModal(subpage)"
                                  />
                                </b-link>
                                <b-link v-if="canEdit" href="#">
                                  <feather-icon
                                    v-b-tooltip.hover.bottom
                                    icon="Edit2Icon"
                                    size="16"
                                    class="create"
                                    :title="$t('form-create-item.edit')"
                                    @click="toggleEdit(subpage)"
                                  />
                                </b-link>
                              </div>
                            </div>
                            <div
                              v-if="subpage.body"
                              class="blog-content content-container"
                              v-html="
                                subpage.body[currentLocale] || Object.values(subpage.body)[0]
                              "
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </b-card>
                </b-col>
              </b-row>
            </div>
          </b-col>
        </b-row>
      </content-with-sidebar>
    </div>
    <!-- Unshare modal -->
    <unshare-modal
      v-model="isUnshareModalVisible"
      model-type="article"
      @remove-item="handleRemoveItem"
    />
    <!-- Share modal -->
    <share-modal
      v-model="isShareModalVisible"
      :can-share="canShare"
      :item-name="item.title"
      model-type="article"
      type="articles"
      :model-key="id"
    />
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive';
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue';
import Placeholder from '@/assets/images/placeholders/light/placeholder-dark.jpeg';
import ContentWithSidebar from '@core/layouts/components/content-with-sidebar/ContentWithSidebarLeft.vue';
import moment from 'moment';
import { getImageResource } from '@/@core/utils/image-utils';
import { quillEditor } from 'vue-quill-editor';
import FormTranslationTable from '@core/components/form-translation-table/FormTranslationTable.vue';
import vSelect from 'vue-select';
import UnshareModal from '@/@core/components/modal/UnshareModal.vue';
import { ENABLED_APPS_GETTERS } from '@/store/enabled-apps/enabled-apps-store-constants';
import { AppIDLUT } from '@copernicsw/community-common';
import { translateTranslationTable } from '@/@core/libs/i18n/utils';
import { checkPermissions } from '@/@core/utils/roles-utils';
import ShareModal from '@/@core/components/modal/ShareModal.vue';
import VueScroll from 'vuescroll';
import ArticleCreate from './ArticleCreate.vue';

export default {
  name: 'ArticleDetails',
  components: {
    ContentWithSidebar,
    quillEditor,
    FormTranslationTable,
    vSelect,
    UnshareModal,
    ArticleCreate,
    ShareModal,
    VueScroll,
  },
  directives: {
    Ripple,
  },
  /* props: {
    id: {
      type: String,
      required: true,
    },
  }, */
  data() {
    return {
      isLoading: true,
      isClassifiersModalVisible: false,
      isCustomFieldsModalVisible: false,
      isShareModalVisible: false,
      imageSrc: null,
      newComment: '',
      comments: [],
      editing: false,
      editForm: { name: {}, body: {} },
      imageSrc: null,
      itemImage: null,
      lastLoadedPage: 1,
      selectedLanguage: this.currentLocale,
      isUnshareModalVisible: false,
      isHovered: false,
      isCreating: false,
      showNavigation: true,
      showList: true,
      elementId: '',
      mainItem: null,
      itemToDelete: '',
      itemToDeleteParentKey: '',
      quillEditorOptions: {
        placeholder: 'holaa',
      },
    };
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
    loggedMemberRoles() {
      return this.$store.getters.loggedMemberRoles;
    },
    loggedMember() {
      return this.$store.getters.loggedMember;
    },
    canEdit() {
      return (
        this.collective.isStaff
        || this.loggedMember?.key === this.item.ownedByMember?.key
        || checkPermissions(
          'update',
          'article',
          this.loggedMemberRoles,
          this.$store.getters.currentCollective,
        )
      );
    },
    canDelete() {
      return (
        this.collective.isStaff
        || this.loggedMember?.key === this.item.ownedByMember?.key
        || checkPermissions(
          'delete',
          'article',
          this.loggedMemberRoles,
          this.$store.getters.currentCollective,
        )
      );
    },
    canAdd() {
      return (
        this.collective.isStaff
        || this.loggedMember?.key === this.item.ownedByMember?.key
        || checkPermissions('create', 'article', this.loggedMemberRoles, this.collective)
      );
    },
    locales() {
      return this.$store.getters.locales;
    },
    currentLocale() {
      return this.$store.state.locale.currentLocale;
    },
    item() {
      if (this.$store.getters.articles.articles) {
        const article = this.$store.getters.fragmentedItem('articles', this.id, 'articles');
        return article;
      }
      return {};
    },

    recentPosts() {
      const posts = this.$store.getters.articles.articles.unpaginated;
      const selectedPosts = posts.filter((post) => post.key !== this.item.key);
      this.updateBreadcrumbs();
      return selectedPosts.slice(0, 6);
    },
    childrenItem() {
      if (this.$store.getters.articles[this.id]) {
        const pages = this.$store.getters.articles[this.id].unpaginated;
        for (const page of pages) {
          if (this.$store.getters.articles[page.key]) {
            const subpages = this.$store.getters.articles[page.key].unpaginated;
            page.subpages = subpages;
          }
        }
        return pages;
      }
      return [];
    },

    placeholder() {
      return Placeholder;
    },
    classifiersData() {
      if (this.$store.getters.classifiers.article) {
        return this.$store.getters.classifiers.article.unpaginated;
      }
      return [];
    },
    classifiers() {
      return this.classifiersData;
    },
    collective() {
      return this.$store.state.collective.collective;
    },
    appId() {
      return 104;
    },
    apps() {
      return this.$store.getters.apps;
    },
    app() {
      if (this.apps) {
        return this.apps.apps[this.appId] || {};
      }
      return {};
    },
    canShare() {
      return checkPermissions('share', 'articles', this.loggedMemberRoles, this.$store.getters.currentCollective);
    },
  },
  watch: {
    item(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.comments = [];
        // this.fetchComments();
        //   this.fetchChildren(this.id);
      }
    },
    itemImage(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue) {
          this.base64Encode(newValue)
            .then((value) => {
              this.imageSrc = value;
            })
            .catch(() => {
              this.imageSrc = null;
            });
        } else {
          this.imageSrc = null;
        }
      }
    },
  },

  // Fetch data when component is created!
  async created() {
    this.isLoading = true;
    await this.fetchItem();
    await this.fetchAllLevels();
    this.updateBreadcrumbs();
    // await this.fetchComments();
    this.isLoading = false;
  },

  methods: {
    handleHover(hovered) {
      this.isHovered = hovered;
    },
    onFileChange(e) {
      const files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.itemImage = files[0];
    },
    toggleEdit(item) {
      this.editing = !this.editing;
      if (this.editing) {
        this.selectedLanguage = this.currentLocale;
        this.editForm = { ...item };
      } else {
        this.editForm = {};
      }
    },
    toggleNavigation() {
      this.showNavigation = !this.showNavigation;
    },
    toggleArticle(article) {
      if (article.hideArticle) {
        return article.hideArticle = false;
      }
      return article.hideArticle = true;
    },
    toggleList() {
      this.showList = !this.showList;
    },
    base64Encode(data) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(data);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    async fetchComments() {
      const response = await this.$store.dispatch('getComments', {
        morphType: 'articles',
        key: this.id,
      });
      this.comments = response.data;
    },
    async fetchItem() {
      await this.$store.dispatch('getItems', {
        itemType: 'articles',
        storedKey: 'articles',
        page: 1,
        requestConfig: {
          count: 16,
        },
      });
    },
    async fetchChildren(id) {
      const response = await this.$store.dispatch('getItems', {
        itemType: 'articles',
        storedKey: id,
        page: 1,
        perPage: 16,
        forceAPICall: true,
        requestConfig: {
          parentKey: id,
          orderByDate: 1,
        },
      });
      return response.data;
    },
    async fetchAllLevels() {
      const firstLevel = await this.fetchChildren(this.id);
      for (const item of firstLevel) {
        await this.fetchChildren(item.key);
      }
    },
    createNewPage(id, mainItem) {
      this.showNavigation = true;
      this.elementId = id;
      this.mainItem = mainItem || null;
      this.isCreating = true;
    },
    timestampToTime(timestamp) {
      moment.locale(this.currentLocale);
      return moment(timestamp).format('LL');
    },
    timestampToNow(timestamp) {
      moment.locale(this.currentLocale);
      return moment(timestamp).fromNow();
    },
    getImageSrc(url) {
      return getImageResource(url);
    },
    async fetchClassifiers() {
      await this.$store.dispatch('getItems', {
        storedKey: 'articles',
        itemType: 'classifiers',
        page: 1,
        forceAPICall: true,
        requestConfig: {
          blogKey: this.item?.key,
        },
      });
    },
    async toggleLike() {
      await this.$store.dispatch('toggleLike', {
        itemType: 'articles',
        morphType: 'articles',
        key: this.item?.key,
      });
    },
    async scrollBottom() {
      const element = document.getElementById('commentForm');
      element.scrollIntoView({ behavior: 'smooth' });
    },
    async postComment() {
      await this.$store.dispatch('postComment', {
        morphType: 'articles',
        key: this.item?.key,
        comment: this.newComment,
      });
      this.newComment = '';
      this.comments = [];
      this.fetchComments();
      this.item.commentCount++;
    },
    // TODO: Remove this workaround. The BE should always return objects
    getText(value) {
      if (!value) return;
      if (typeof value === 'object') { return value[this.currentLocale] || Object.values(value)[0]; }
      if (value[0] === '<') return value;
      const object = JSON.parse(value);
      return object[this.currentLocale] || Object.values(object)[0];
    },
    async closeModal() {
      await this.fetchAllLevels();
      this.isCreating = false;
      this.updateBreadcrumbs();
    },
    async handleEditItem() {
      try {
        const newStoredKey = this.editForm.key === this.id ? 'articles' : this.editForm.parentKey;
        const result = await this.$store.dispatch('editItem', {
          item: {
            itemType: 'articles',
            storedKey: newStoredKey,
            requestConfig: {
              articleKey: this.editForm.key,
              name: this.editForm.name,
              body: this.editForm.body,
            },
          },
          file: this.itemImage,
        });
        this.updateBreadcrumbs();
        await this.fetchChildren(this.editForm.key);
        this.editing = false;
      } catch {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: this.$t('error-message.general-error'),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        });
      }
    },
    // TODO: move to utils
    updateBreadcrumbs() {
      const breadcrumbs = [
        {
          text:
            translateTranslationTable(
              this.$store.state.locale.currentLocale,
              this.app?.customizationName,
            ) || this.$t('articles.title'),
          to: { name: 'articles' },
        },
        {
          text:
            this.item?.name[this.currentLocale]
            || Object.values(this.item?.name)[0]
            || 'articles.breadcrumb-text.article-details',
          active: true,
        },
      ];
      this.$store.commit('app/SET_BREADCRUMBS', breadcrumbs);
    },
    openUnshareModal(item) {
      this.itemToDelete = item.key;
      if (item.parentKey) {
        this.itemToDeleteParentKey = item.parentKey;
      }
      this.isUnshareModalVisible = true;
    },
    async handleRemoveItem(response) {
      if (response === true) {
        const newStoredKey = this.itemToDelete === this.id ? 'articles' : this.itemToDeleteParentKey;
        try {
          await this.$store.dispatch('unshareItem', {
            item: {
              itemType: 'articles',
              storedKey: newStoredKey,
              key: this.itemToDelete,
              articleKey: this.itemToDelete,
            },
          });
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: this.$t('unshare.toast-messages.success', {
                item: this.$t('unshare.singular-items.availability'),
              }),
              icon: 'CheckIcon',
              variant: 'success',
            },
          });
          this.isUnshareModalVisible = false;
          if (this.itemToDelete === this.id) {
            return this.$router.replace({
              name: 'articles',
            });
          }
          this.itemToDelete = '';
          this.itemToDeleteParentKey = '';
        } catch {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: this.$t('unshare.toast-messages.error', {
                item: this.$t('unshare.singular-items.article'),
              }),
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });
          this.itemToDelete = '';
          this.itemToDeleteParentKey = '';
        }
      }
    },
  },
};
</script>
<style lang="scss">
@import '~@core/scss/base/bootstrap-include'; // Bootstrap includes

.content-container img {
  max-width: 100%;
}
.index {
  background-color: white;
  border-radius: 1.2rem;
  border: 1px solid rgba(34, 41, 47, 0.125);
}
.title-index {
  border-top: 1px solid #b9b9c3;
}
.blog-container {
  max-width: auto;
  margin: 0 auto !important;
}
.cws-container .cws-content {
  width: calc(100% - 460px - 2rem);
}
.cws-container .cws-sidebar {
  width: 460px;
}

.imagen {
  max-height: 500px !important;
  object-fit: cover !important;
  overflow: hidden;
}

.edit-title {
  font-size: 1.5rem;
  color: #5e5873;
  font-weight: 500;
}
.ql-container .ql-editor span {
  font-family: "Montserrat", Helvetica, Arial, serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
}
.ql-container div {
  min-height: 38vh !important;
}

.ql-container .ql-editor p {
  font-family: "Montserrat", Helvetica, Arial, serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  color: black;
}
.form-file-custom {
  position: absolute;
  top: 8px;
  right: 2%;
}
.card-imgage-edit {
  position: absolute;
  top: 8px;
  right: 8px;
  padding: 0.85rem;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.2);
  height: 45px;
  width: 45px;
}
.card-imgage-edit svg {
  color: #f0f0f0 !important;
}
.subitem{
  .create {
    display: none;
    color: $primary;
  };
  &:hover{
    background-color:rgba($dark, 0.05);
    .create{
      display:block;
      cursor: pointer;
    }
  }
}
</style>
