<template>
  <div class="cws-container cws-sidebar-left">
    <!-- content -->
    <div class="cws-content-right">
      <slot />
    </div>

    <!-- Sidebar -->
    <div class="cws-sidebar">
      <slot name="sidebar" />
    </div>
  </div>
</template>

<script>
export default {

};
</script>

<style lang="scss">
@import '~@core/scss/base/bootstrap-include';

$cws-sidebar-width: 260px;
$gap: 2rem;

.cws-container {
  position: relative;

  .cws-content {
      width: calc(100% - #{$cws-sidebar-width} - #{$gap});
      @media (max-width: (map-get($grid-breakpoints, xl))) {
        width: 100%;
      }
  }

  .cws-sidebar {
    width: 20px;
    position: absolute;
    left: 0;
    top: 0;

    @media (max-width: (map-get($grid-breakpoints, xl))) {
      margin-top: $gap;
      position: static;
      width: 80%;
    }
  }
  
}
</style>
