<template>
  <b-modal
    :title="$t('unshare.title', { item: itemName })"
    :visible="visible"
    size="lg"
    hide-footer
    @change="$emit('change', false)"
  >
    <feather-icon size="30" icon="AlertTriangleIcon" class="d-block mx-auto text-danger mb-1" />
    <h5 class="mb-2 px-2 text-center">
      {{ message }}
    </h5>
    <h4 v-if="isHard" class="mb-2 px-2 text-center">
      {{ $t('unshare.not-recoverable') }}
    </h4>
    <h4 v-if="member" class="mb-2 px-2 text-center">
      {{ member.name }} {{ member.surname }}
    </h4>
    <div class="d-flex justify-content-center mb-2 flex-wrap">
      <b-button
        variant="primary"
        target="_blank"
        class="mt-25"
        @click="handleRemoveItem(false)"
      >
        {{ $t('unshare.options.no') }}
      </b-button>
      <b-button
        variant="outline-danger"
        target="_blank"
        class="ml-md-1 mt-25"
        @click="handleRemoveItem(true)"
      >
        {{ $t('unshare.options.yes') }}
      </b-button>
    </div>
  </b-modal>
</template>

<script>
import { BButton } from 'bootstrap-vue';

export default {
  name: 'UnshareModal',
  components: { BButton },
  model: {
    prop: 'visible',
    event: 'change',
  },
  props: {
    visible: Boolean,
    modelType: {
      type: String,
      required: false,
    },
    member: {
      type: Object,
      required: false,
    },
    customMessage: {
      type: String,
    },
    isHard: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    itemName() {
      return this.modelType ? this.$t(`unshare.singular-items.${this.modelType}`) : null;
    },
    message() {
      return this.customMessage ? this.customMessage : this.$t('unshare.message');
    },
  },
  methods: {
    handleRemoveItem(response) {
      this.$emit('remove-item', response);
      this.$emit('change', false);
    },
  },
};
</script>
